import clsx from 'clsx';
import Link from 'next/link';
import { useParams, usePathname } from 'next/navigation';
import { api } from '~/utils/api';
import { serverError } from '~/utils/serverError';

import { Routes } from '../MainNavBar';
import Initials from './Initials';
import { TaskManagerType } from '@prisma/client';

export default function ProjectBar() {
  const params = useParams();
  const pathname = usePathname();
  const isProjectsPage =
    pathname?.startsWith(Routes.PROJECTS) || pathname?.startsWith(Routes.CLAIMS_APPROVAL);

  const { data: projects } = api.task.getProjects.useQuery(
    {
      type: !!pathname?.startsWith(Routes.CLAIMS_APPROVAL)
        ? TaskManagerType.CLAIM_APPROVAL
        : !!pathname?.startsWith(Routes.REMEDIATION)
          ? TaskManagerType.REMEDIATION
          : TaskManagerType.MARKETING,
    },
    {
      onError: serverError,
      enabled: isProjectsPage,
    },
  );

  if (!isProjectsPage || (projects && projects.length <= 1)) return <></>;

  return (
    <div className="border-r-[1.5px] border-gray-200 flex flex-col justify-center items-center px-4 space-y-5">
      {projects?.map((p) => (
        <Link
          key={p.id}
          href={`${pathname?.startsWith(Routes.PROJECTS) ? Routes.PROJECTS : Routes.CLAIMS_APPROVAL}/${p.id}/list`}
          data-tip={p.name}
          className="tooltip tooltip-right"
        >
          <Initials
            characterLimit={3}
            name={p.name}
            className={clsx(params?.remoteTaskManagerId !== p.id && 'text-[#909799] !bg-gray-200')}
          />
        </Link>
      ))}
    </div>
  );
}
